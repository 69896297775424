// vars

%clearfix {
	&:after, &:before {
		content: '';
		display: table; }
	&:after {
		clear: both; } }

// @extend %clearfix
