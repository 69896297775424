@import "bourbon/bourbon";
@import "vars";


html .loader {
	background: none repeat scroll 0 0 #ffffff;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	.loader_inner {
		background-image: url("../images/preloader.gif");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: #fff;
		height: 60px;
		width: 60px;
		margin-top: -30px;
		margin-left: -30px;
		left: 50%;
		top: 50%;
		position: absolute; } }

body {
	font-family: "Open Sans", sans-serif;
	font-weight: normal;
	font-size: 14px;
	min-width: 320px;
	position: relative;
	line-height: 20px;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
	color: #282828; }


body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: red; }
body input:required:valid,
body textarea:required:valid {
	color: green; }

.hidden {
	display: none; }


button, img, div, a, input, textarea {
	&:active, &:focus {
		outline: none !important; }
	&::-moz-focus-inner {
		border: 0 !important; } }


input, textarea, select {
	-webkit-appearance: none;
	-webkit-border-radius: 0px; }


img {
	max-width: 100%;
	height: auto; }






.no-flexbox {
	html, body {
		height: auto; }
	body {
		display: block; }
	header,
	footer {
		display: block; }
	.main {
		display: block; } }

/* колонки одинаковой высоты */
/* flex */
.row-flex {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	flex-wrap: wrap; }

.row-flex {
	> div {
		box-sizing: border-box;
		margin: -.3px; } }

/* no flex */
.no-flexbox {
	.row-flex {
		display: block;
		> div {
			display: inline-block;
			float: none;
			box-sizing: border-box;
			vertical-align: top;
			margin: 0 -2.5px auto; } } }

/* mobile link no focus */
* {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
	-webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
	outline: none !important; }

/* material icons IE */
.material-icons {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga'; }


/*---- DEFAULT STYLES ----*/


.default_btn {
	height: 48px;
	display: inline-block;
	text-decoration: none;
	width: 200px;
	text-align: center;
	background-color: transparent;
	padding: 3px;
	transition: all .5s ease;
	&:hover {
		text-decoration: none; }
	span {
		display: block;
		height: 100%;
		line-height: 40px; }
	&-red {
		color: #fff;
		border: 1px solid #822c0e;
		&:hover,
		&:focus {
			color: #fff;
			background-color: #822c0e; }
		span {
			background-color: #822c0e; } }
	&-white {
		color: #282828;
		border: 1px solid #fff;
		&:hover,
		&:focus {
			color: #282828;
			background-color: #fff; }
		span {
			background-color: #fff; } } }


.section_head_block {
	.descr {
		margin-top: 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		text-align: center; } }
.section_head {
	color: #822c0e;
	font-family: 'YesevaOne';
	font-size: 48px;
	font-weight: 400;
	line-height: 54px;
	text-align: center;
	margin-bottom: 50px;
	display: block;
	&.left {
		text-align: left; } }


.head {
	color: #822c0e;
	margin: 0;
	font-family: 'YesevaOne';
	font-weight: 400;
	display: block;
	&-big {
		font-size: 60px;
		line-height: 60px; }
	&-medium {
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 25px; } }

section.sect {
	padding-top: 60px;
	padding-bottom: 60px; }


.ol_variant_one {
	margin: 20px 0;
	padding: 0;
	list-style-type: none;
	counter-reset: myCounter;
	li {
		display: block;
		position: relative;
		padding-left: 18px;
		&::after {
			counter-increment: myCounter;
			content: counter(myCounter)')';
			color: #282828;
			position: absolute;
			top: 0px;
			left: 0; } } }

.ol_variant_two {
	margin: 20px 0;
	padding: 0;
	list-style-type: none;
	counter-reset: myCounter;
	padding-left: 20px;
	li {
		display: block;
		position: relative;
		padding-left: 30px;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0; }
		&::after {
			counter-increment: myCounter;
			content: counter(myCounter)'.';
			color: #822c0e;
			font-size: 16px;
			font-weight: bold;
			position: absolute;
			top: 0px;
			left: 0; } } }

ul {
	list-style-type: none;
	margin: 30px 0;
	padding-left: 20px;
	li {
		padding-left: 20px;
		position: relative;
		&::after {
			content: '-';
			position: absolute;
			top: 0;
			left: 0; } } }

/*---- BLOCK STYLES ----*/




/*---- HEADER ---- */

header.main_header {
	background-image: url(../images/header/banner.jpg);
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	padding-top: 30px;
	padding-bottom: 20px;
	background-size: cover;
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0,0,0,.65);
		z-index: 1; }
	.main_header_top {
		position: relative;
		z-index: 2; }
	.main_header_middle {
		position: relative;
		z-index: 2; } }

.logo_block {
	> * {
		display: inline-block;
		vertical-align: middle; }
	.logo_img {
		margin-right: 15px; }
	.logo_text {
		margin-top: 5px;
		> span {
			display: block;
			color: white;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase; } } }

.header_contacts {
	text-align: right;
	margin-top: 20px;
	> div {
		display: inline-block;
		vertical-align: middle; } }

.header_contacts_info {
	a {
		color: #fff;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline; } }
	.header_contacts_phone {
		font-family: 'YesevaOne';
		font-size: 26px;
		font-weight: 400;
		line-height: 24px;
		text-transform: uppercase; }
	.header_contacts_email {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px; } }

.header_contacts_callback {
	margin-left: 30px;
	a {
		width: 160px;
		.icon {
			display: none;
			font-size: 22px;
			line-height: 30px; } } }


.main_header_middle {
	margin-top: 45px;
	h1 {
		color: white;
		font-family: 'YesevaOne', sans-serif;
		font-size: 48px;
		font-weight: 400;
		line-height: 60px;
		text-align: center;
		margin: 0;
		animation-delay: 1s; } }


.info_icons_block {
	margin-top: 70px;
	.item {
		text-align: center;
		margin-bottom: 50px;
		.item_icon {
			height: 125px; }
		.item_descr {
			color: white;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			margin-top: 42px; } } }



/*---- FOOTER ---- */



/*---- PAGE STYLES ----*/

section.sect_about {
	padding-top: 0; }

.about_section_block_list {}


.red {
	color: #822c0e; }



.about_section_item_content {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	> div {
		&:first-child {
			padding-right: 30px; } }
	> div {
		width: 50%; }
	.img-wrap {
		flex-grow: 0; } }

.no-flexbox {
	.about_section_item_content {
		display: block;
		font-size: 0;
		> div {
			display: inline-block;
			width: 50%;
			vertical-align: top;
			&:first-child {
				padding-right: 30px; } } } }

.about_section_item {
	padding: 80px 0;
	position: relative;
	.item_bg_img {
		position: absolute;
		top: 0px;
		max-width: 48%; }
	&:nth-child(odd) {
		.item_bg_img {
			right: 0;
			z-index: -1; } }
	&:nth-child(even) {
		.item_bg_img {
			left: 0;
			z-index: -1; } }
	&:first-child {
		.item_text {
			font-size: 18px;
			line-height: 24px;
			margin-top: -35px; } }
	.item_text {
		font-size: 14px;
		line-height: 20px;
		.item_head {
			.section_head {
				text-align: left;
				margin-bottom: 40px; } } }
	.img-wrap {
		padding: 5px;
		display: inline-block;
		border: 1px solid #822c0e; }
	&:nth-child {} }


section.sect_stage_work {
	background-color: #822c0e;
	background-image: url(../images/sect_stage_work/bg.jpg);
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: cover;
	.section_head {
		color: #fff; } }

.stage_list {
	color: #fff;
	position: relative;
	&::after {
		content: '';
		width: 3px;
		background-color: #fff;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2; }
	.item_wrap {
		margin-bottom: 30px;
		&:nth-child(odd) {
			.item {
				float: right;
				padding-left: 65px;
				position: relative;
				text-align: left;
				&::after {
					content: '';
					width: 20px;
					height: 20px;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					left: -10px;
					top: 50%;
					transform: translateY(-50%); }
				.item_number {
					padding-right: 15px; } } }
		&:nth-child(even) {
			.item {
				float: left;
				padding-right: 65px;
				position: relative;
				text-align: right;
				&::after {
					content: '';
					width: 20px;
					height: 20px;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					right: -10px;
					top: 50%;
					transform: translateY(-50%); }
				.item_number {
					padding-left: 15px; } } } }
	.item {
		width: 50%;
		display: table;
		padding: 10px 0;
		> div {
			display: table-cell;
			vertical-align: middle; }
		.item_number {
			font-family: 'YesevaOne', sans-serif;
			font-size: 48px;
			font-weight: 400;
			line-height: 40px; }

		.item_descr {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px; } } }


section.sect_advantages {
	padding-bottom: 40px; }
.advantages_list {
	padding-top: 20px;
	.item {
		display: table;
		margin-bottom: 40px;
		width: 100%;
		> div {
			display: table-cell;
			vertical-align: middle; }
		.item_icon {
			width: 95px; }
		.item_text {
			padding-left: 20px;
			color: #282828;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px; } } }


section.sect_question {
	background-color: #f7f7f7; }


.question_slider {
	.slide_head {
		color: #822c0e;
		font-family: 'YesevaOne', sans-serif;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 25px; }
	.slide_content {} }



.owl-nav {
	.owl-prev,
	.owl-next {
		width: 40px;
		height: 40px;
		svg {
			width: 40px;
			height: 40px;
			fill: #1f1f1f;
			transition: all .5s ease; }
		&:hover {
			svg {
				fill: #822c0e; } } }
	.owl-prev {
		position: absolute;
		top: 50%;
		left: -103px;
		margin-top: -20px; }
	.owl-next {
		position: absolute;
		top: 50%;
		right: -103px;
		margin-top: -20px;
		transform: rotate(180deg); } }


.sect_callback_question {
	background-image: url(../images/sect_callback_question/bg.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	.section_head_block {
		margin-bottom: 75px;
		.section_head {
			margin-bottom: 10px;
			color: #fff; }
		.descr {
			max-width: 70%;
			margin: 0 auto;
			color: #fff; } } }



.default_popup {
	padding: 40px 20px 30px;
	.fancybox-close-small:after {
		font-size: 34px; }
	.default_form {
		.form_content {
			.input_wrap {
				margin-bottom: 20px; } }
		.form_footer {
			margin-top: 30px; } } }

.default_form {
	.form_head {
		margin-bottom: 30px;
		.head {
			display: block;
			text-align: center;
			font-family: 'YesevaOne', sans-serif;
			font-size: 24px;
			font-weight: 400;
			line-height: 24px;
			color: #822c0e; } }
	.form_content {
		.input_wrap {
			margin-bottom: 35px;
			input,
			textarea {
				width: 100%;
				border: 1px solid #acacac;
				padding: 0 15px;
				color: #282828;
				transition: all .5s ease;
				&::placeholder {
					color: #282828;
					font-size: 14px; }
				&:focus {
					border: 1px solid #822c0e; } }
			input {
				height: 40px; }
			textarea {
				height: 130px;
				padding: 15px;
				resize: none; } } }
	.form_footer {
		text-align: center;
		.button_wrap {
			.default_btn {
				width: 285px; } } } }

.callback_question_form_wrap {
	border: 1px solid #fff;
	padding: 5px;
	.callback_question_form_wrap-content {
		background-color: rgba(255,255,255,.9);
		padding: 70px 0 80px; } }





footer.main_footer {
	padding: 60px 0 65px;
	background-color: #1f1f1f;
	color: #fff;
	.main_footer_right {
		@extend %clearfix;
		> div {
			display: inline-block; }
		.footer_contacts_callback {
			float: right; } } }


.footer_links {
	margin-top: 10px;
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		li {
			display: inline-block;
			padding-left: 0;
			margin-right: 40px;
			&:last-child {
				margin-right: 0; }
			a {
				color: #fff;
				opacity: 0.5;
				font-size: 14px;
				font-weight: 400;
				line-height: 24px; }
			&:after {
				display: none; } } } }


.contacts_block {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	.contacts_block-item {
		margin-bottom: 5px;
		&:last-child {
			margin-bottom: 0; } }
	a {
		color: #fff;
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline; } } }
.footer_contacts_callback {
	a {
		width: 160px; } }


.callback_form_popup {
	width: 400px; }


/* animation */

.about_section_item {
	.item_img {
		animation-duration: 1.5s;
		animation-delay: .5s; }
	.item_text {
		animation-delay: .3s;
		animation-duration: 1s; } }

.advantages_list {
	.item {
		animation-delay: .5s; } }

.callback_question_form_wrap {
	animation-delay: .5s; }
