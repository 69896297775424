@charset "UTF-8";
footer.main_footer .main_footer_right:after, footer.main_footer .main_footer_right:before {
  content: '';
  display: table; }

footer.main_footer .main_footer_right:after {
  clear: both; }

html .loader {
  background: none repeat scroll 0 0 #ffffff;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999; }
  html .loader .loader_inner {
    background-image: url("../images/preloader.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    margin-left: -30px;
    left: 50%;
    top: 50%;
    position: absolute; }

body {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 14px;
  min-width: 320px;
  position: relative;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: #282828; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
  color: red; }

body input:required:valid,
body textarea:required:valid {
  color: green; }

.hidden {
  display: none; }

button:active, button:focus, img:active, img:focus, div:active, div:focus, a:active, a:focus, input:active, input:focus, textarea:active, textarea:focus {
  outline: none !important; }

button::-moz-focus-inner, img::-moz-focus-inner, div::-moz-focus-inner, a::-moz-focus-inner, input::-moz-focus-inner, textarea::-moz-focus-inner {
  border: 0 !important; }

input, textarea, select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px; }

img {
  max-width: 100%;
  height: auto; }

.no-flexbox html, .no-flexbox body {
  height: auto; }

.no-flexbox body {
  display: block; }

.no-flexbox header,
.no-flexbox footer {
  display: block; }

.no-flexbox .main {
  display: block; }

/* колонки одинаковой высоты */
/* flex */
.row-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap; }

.row-flex > div {
  box-sizing: border-box;
  margin: -.3px; }

/* no flex */
.no-flexbox .row-flex {
  display: block; }
  .no-flexbox .row-flex > div {
    display: inline-block;
    float: none;
    box-sizing: border-box;
    vertical-align: top;
    margin: 0 -2.5px auto; }

/* mobile link no focus */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important; }

/* material icons IE */
.material-icons {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'; }

/*---- DEFAULT STYLES ----*/
.default_btn {
  height: 48px;
  display: inline-block;
  text-decoration: none;
  width: 200px;
  text-align: center;
  background-color: transparent;
  padding: 3px;
  transition: all .5s ease; }
  .default_btn:hover {
    text-decoration: none; }
  .default_btn span {
    display: block;
    height: 100%;
    line-height: 40px; }
  .default_btn-red {
    color: #fff;
    border: 1px solid #822c0e; }
    .default_btn-red:hover, .default_btn-red:focus {
      color: #fff;
      background-color: #822c0e; }
    .default_btn-red span {
      background-color: #822c0e; }
  .default_btn-white {
    color: #282828;
    border: 1px solid #fff; }
    .default_btn-white:hover, .default_btn-white:focus {
      color: #282828;
      background-color: #fff; }
    .default_btn-white span {
      background-color: #fff; }

.section_head_block .descr {
  margin-top: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center; }

.section_head {
  color: #822c0e;
  font-family: 'YesevaOne';
  font-size: 48px;
  font-weight: 400;
  line-height: 54px;
  text-align: center;
  margin-bottom: 50px;
  display: block; }
  .section_head.left {
    text-align: left; }

.head {
  color: #822c0e;
  margin: 0;
  font-family: 'YesevaOne';
  font-weight: 400;
  display: block; }
  .head-big {
    font-size: 60px;
    line-height: 60px; }
  .head-medium {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 25px; }

section.sect {
  padding-top: 60px;
  padding-bottom: 60px; }

.ol_variant_one {
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: myCounter; }
  .ol_variant_one li {
    display: block;
    position: relative;
    padding-left: 18px; }
    .ol_variant_one li::after {
      counter-increment: myCounter;
      content: counter(myCounter) ")";
      color: #282828;
      position: absolute;
      top: 0px;
      left: 0; }

.ol_variant_two {
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
  counter-reset: myCounter;
  padding-left: 20px; }
  .ol_variant_two li {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px; }
    .ol_variant_two li:last-child {
      margin-bottom: 0; }
    .ol_variant_two li::after {
      counter-increment: myCounter;
      content: counter(myCounter) ".";
      color: #822c0e;
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      top: 0px;
      left: 0; }

ul {
  list-style-type: none;
  margin: 30px 0;
  padding-left: 20px; }
  ul li {
    padding-left: 20px;
    position: relative; }
    ul li::after {
      content: '-';
      position: absolute;
      top: 0;
      left: 0; }

/*---- BLOCK STYLES ----*/
/*---- HEADER ---- */
header.main_header {
  background-image: url(../images/header/banner.jpg);
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
  background-size: cover; }
  header.main_header::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 1; }
  header.main_header .main_header_top {
    position: relative;
    z-index: 2; }
  header.main_header .main_header_middle {
    position: relative;
    z-index: 2; }

.logo_block > * {
  display: inline-block;
  vertical-align: middle; }

.logo_block .logo_img {
  margin-right: 15px; }

.logo_block .logo_text {
  margin-top: 5px; }
  .logo_block .logo_text > span {
    display: block;
    color: white;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase; }

.header_contacts {
  text-align: right;
  margin-top: 20px; }
  .header_contacts > div {
    display: inline-block;
    vertical-align: middle; }

.header_contacts_info a {
  color: #fff;
  text-decoration: none; }
  .header_contacts_info a:hover, .header_contacts_info a:focus {
    text-decoration: underline; }

.header_contacts_info .header_contacts_phone {
  font-family: 'YesevaOne';
  font-size: 26px;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase; }

.header_contacts_info .header_contacts_email {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px; }

.header_contacts_callback {
  margin-left: 30px; }
  .header_contacts_callback a {
    width: 160px; }
    .header_contacts_callback a .icon {
      display: none;
      font-size: 22px;
      line-height: 30px; }

.main_header_middle {
  margin-top: 45px; }
  .main_header_middle h1 {
    color: white;
    font-family: 'YesevaOne', sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    margin: 0;
    animation-delay: 1s; }

.info_icons_block {
  margin-top: 70px; }
  .info_icons_block .item {
    text-align: center;
    margin-bottom: 50px; }
    .info_icons_block .item .item_icon {
      height: 125px; }
    .info_icons_block .item .item_descr {
      color: white;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 42px; }

/*---- FOOTER ---- */
/*---- PAGE STYLES ----*/
section.sect_about {
  padding-top: 0; }

.red {
  color: #822c0e; }

.about_section_item_content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }
  .about_section_item_content > div:first-child {
    padding-right: 30px; }
  .about_section_item_content > div {
    width: 50%; }
  .about_section_item_content .img-wrap {
    flex-grow: 0; }

.no-flexbox .about_section_item_content {
  display: block;
  font-size: 0; }
  .no-flexbox .about_section_item_content > div {
    display: inline-block;
    width: 50%;
    vertical-align: top; }
    .no-flexbox .about_section_item_content > div:first-child {
      padding-right: 30px; }

.about_section_item {
  padding: 80px 0;
  position: relative; }
  .about_section_item .item_bg_img {
    position: absolute;
    top: 0px;
    max-width: 48%; }
  .about_section_item:nth-child(odd) .item_bg_img {
    right: 0;
    z-index: -1; }
  .about_section_item:nth-child(even) .item_bg_img {
    left: 0;
    z-index: -1; }
  .about_section_item:first-child .item_text {
    font-size: 18px;
    line-height: 24px;
    margin-top: -35px; }
  .about_section_item .item_text {
    font-size: 14px;
    line-height: 20px; }
    .about_section_item .item_text .item_head .section_head {
      text-align: left;
      margin-bottom: 40px; }
  .about_section_item .img-wrap {
    padding: 5px;
    display: inline-block;
    border: 1px solid #822c0e; }

section.sect_stage_work {
  background-color: #822c0e;
  background-image: url(../images/sect_stage_work/bg.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover; }
  section.sect_stage_work .section_head {
    color: #fff; }

.stage_list {
  color: #fff;
  position: relative; }
  .stage_list::after {
    content: '';
    width: 3px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
  .stage_list .item_wrap {
    margin-bottom: 30px; }
    .stage_list .item_wrap:nth-child(odd) .item {
      float: right;
      padding-left: 65px;
      position: relative;
      text-align: left; }
      .stage_list .item_wrap:nth-child(odd) .item::after {
        content: '';
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%); }
      .stage_list .item_wrap:nth-child(odd) .item .item_number {
        padding-right: 15px; }
    .stage_list .item_wrap:nth-child(even) .item {
      float: left;
      padding-right: 65px;
      position: relative;
      text-align: right; }
      .stage_list .item_wrap:nth-child(even) .item::after {
        content: '';
        width: 20px;
        height: 20px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%); }
      .stage_list .item_wrap:nth-child(even) .item .item_number {
        padding-left: 15px; }
  .stage_list .item {
    width: 50%;
    display: table;
    padding: 10px 0; }
    .stage_list .item > div {
      display: table-cell;
      vertical-align: middle; }
    .stage_list .item .item_number {
      font-family: 'YesevaOne', sans-serif;
      font-size: 48px;
      font-weight: 400;
      line-height: 40px; }
    .stage_list .item .item_descr {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; }

section.sect_advantages {
  padding-bottom: 40px; }

.advantages_list {
  padding-top: 20px; }
  .advantages_list .item {
    display: table;
    margin-bottom: 40px;
    width: 100%; }
    .advantages_list .item > div {
      display: table-cell;
      vertical-align: middle; }
    .advantages_list .item .item_icon {
      width: 95px; }
    .advantages_list .item .item_text {
      padding-left: 20px;
      color: #282828;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px; }

section.sect_question {
  background-color: #f7f7f7; }

.question_slider .slide_head {
  color: #822c0e;
  font-family: 'YesevaOne', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px; }

.owl-nav .owl-prev,
.owl-nav .owl-next {
  width: 40px;
  height: 40px; }
  .owl-nav .owl-prev svg,
  .owl-nav .owl-next svg {
    width: 40px;
    height: 40px;
    fill: #1f1f1f;
    transition: all .5s ease; }
  .owl-nav .owl-prev:hover svg,
  .owl-nav .owl-next:hover svg {
    fill: #822c0e; }

.owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: -103px;
  margin-top: -20px; }

.owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: -103px;
  margin-top: -20px;
  transform: rotate(180deg); }

.sect_callback_question {
  background-image: url(../images/sect_callback_question/bg.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }
  .sect_callback_question .section_head_block {
    margin-bottom: 75px; }
    .sect_callback_question .section_head_block .section_head {
      margin-bottom: 10px;
      color: #fff; }
    .sect_callback_question .section_head_block .descr {
      max-width: 70%;
      margin: 0 auto;
      color: #fff; }

.default_popup {
  padding: 40px 20px 30px; }
  .default_popup .fancybox-close-small:after {
    font-size: 34px; }
  .default_popup .default_form .form_content .input_wrap {
    margin-bottom: 20px; }
  .default_popup .default_form .form_footer {
    margin-top: 30px; }

.default_form .form_head {
  margin-bottom: 30px; }
  .default_form .form_head .head {
    display: block;
    text-align: center;
    font-family: 'YesevaOne', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #822c0e; }

.default_form .form_content .input_wrap {
  margin-bottom: 35px; }
  .default_form .form_content .input_wrap input,
  .default_form .form_content .input_wrap textarea {
    width: 100%;
    border: 1px solid #acacac;
    padding: 0 15px;
    color: #282828;
    transition: all .5s ease; }
    .default_form .form_content .input_wrap input::placeholder,
    .default_form .form_content .input_wrap textarea::placeholder {
      color: #282828;
      font-size: 14px; }
    .default_form .form_content .input_wrap input:focus,
    .default_form .form_content .input_wrap textarea:focus {
      border: 1px solid #822c0e; }
  .default_form .form_content .input_wrap input {
    height: 40px; }
  .default_form .form_content .input_wrap textarea {
    height: 130px;
    padding: 15px;
    resize: none; }

.default_form .form_footer {
  text-align: center; }
  .default_form .form_footer .button_wrap .default_btn {
    width: 285px; }

.callback_question_form_wrap {
  border: 1px solid #fff;
  padding: 5px; }
  .callback_question_form_wrap .callback_question_form_wrap-content {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 70px 0 80px; }

footer.main_footer {
  padding: 60px 0 65px;
  background-color: #1f1f1f;
  color: #fff; }
  footer.main_footer .main_footer_right > div {
    display: inline-block; }
  footer.main_footer .main_footer_right .footer_contacts_callback {
    float: right; }

.footer_links {
  margin-top: 10px; }
  .footer_links ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .footer_links ul li {
      display: inline-block;
      padding-left: 0;
      margin-right: 40px; }
      .footer_links ul li:last-child {
        margin-right: 0; }
      .footer_links ul li a {
        color: #fff;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px; }
      .footer_links ul li:after {
        display: none; }

.contacts_block {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; }
  .contacts_block .contacts_block-item {
    margin-bottom: 5px; }
    .contacts_block .contacts_block-item:last-child {
      margin-bottom: 0; }
  .contacts_block a {
    color: #fff;
    text-decoration: none; }
    .contacts_block a:hover, .contacts_block a:focus {
      text-decoration: underline; }

.footer_contacts_callback a {
  width: 160px; }

.callback_form_popup {
  width: 400px; }

/* animation */
.about_section_item .item_img {
  animation-duration: 1.5s;
  animation-delay: .5s; }

.about_section_item .item_text {
  animation-delay: .3s;
  animation-duration: 1s; }

.advantages_list .item {
  animation-delay: .5s; }

.callback_question_form_wrap {
  animation-delay: .5s; }
